import { Box, styled, Typography } from "@mui/material";
import { FC, useState } from "react";
import { EditDialogData } from "iam-types";
import {
  FormField,
  FormFields,
  FormRow,
  TextFormField,
  SelectFormField,
  InputFormField,
} from "@pimo/pimo-components";
import { UseFormReturn } from "react-hook-form";

export interface EditKeyInformationFormContentsProps {
  /** generated by `useForm` (`react-hook-forms` package) */
  formMethods: UseFormReturn<EditDialogData>;
  data: EditDialogData;
  /** reporting date line from oe */
  reportingDate: string;
}

const FormHeader = styled(Box)(({ theme }) => ({
  paddingRight: theme.spacing(3),
  display: "flex",
  justifyContent: "space-between",
}));

/** KeyInformation update section of the edit dialog form
 * Should be wrapped in `form`, and `FormProvider<EditDialogData>` (for the date picker)
 */
export const EditKeyInformationFormContents: FC<
  EditKeyInformationFormContentsProps
> = ({ data, reportingDate }) => {
  const [currentLocalTool, setCurrentLocalTool] = useState(data.localIAMTool);

  return (
    <div data-testid="EditKeyInformationForm" style={{ overflowX: "auto" }}>
      <FormHeader>
        <Typography variant="h5" sx={{ padding: 3 }}>
          {`Please update your comment and Key Information${
            reportingDate ? ` (as of ${reportingDate})` : ""
          }`}
        </Typography>
      </FormHeader>

      <FormRow data-testid="KeyInfoFormRow">
        <FormFields>
          <TextFormField
            label={"Comment overall status"}
            name={`commentOverallStatus`}
            rows={10}
          />
          <FormField label={"Key Information"}>
            <Box
              sx={{
                minWidth: "50%",
                display: "flex",
                flexDirection: "column",
                gap: 1,
              }}
            >
              {data.dimension === "iam-model" && (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                    gap: 1,
                  }}
                >
                  <Typography>
                    Business Role Management Tool in Place?{" "}
                  </Typography>
                  <SelectFormField
                    label={""}
                    name={`businessRoleManagementTool`}
                    options={["yes", "no"].map((type) => ({
                      value: type,
                      label: type,
                    }))}
                  />
                </Box>
              )}
              {data.dimension === "iam-gov" && (
                <>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      alignItems: "center",
                      gap: 1,
                    }}
                  >
                    <Typography>OE IAM Governance (#FTE)</Typography>
                    <InputFormField
                      label={""}
                      name={`kpiOEIAMGovernance`}
                      isNumeric
                    />
                  </Box>

                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      alignItems: "center",
                      gap: 1,
                    }}
                  >
                    <Typography>OE IAM Business Operations (#FTE)</Typography>
                    <InputFormField
                      label={""}
                      name={`kpiOEIAMBusinessOperations`}
                      isNumeric
                    />
                  </Box>

                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      gap: 1,
                      alignItems: "center",
                    }}
                  >
                    <Typography>IAM Change Budget 2024 (€)</Typography>
                    <InputFormField
                      label={""}
                      name={`kpiIAMChangeBudget`}
                      isNumeric
                    />
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      gap: 1,
                      alignItems: "center",
                    }}
                  >
                    <Typography>IAM Change Budget 2025 (€)</Typography>
                    <InputFormField
                      label={""}
                      name={`kpiIAMChangeBudget2025`}
                      isNumeric
                    />
                  </Box>
                </>
              )}
              {data.dimension === "iam-tech" && (
                <>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      gap: 1,
                      alignItems: "center",
                    }}
                  >
                    <Typography>Local IAM Tool (name)</Typography>
                    <SelectFormField
                      onValueChange={(e) =>
                        setCurrentLocalTool(
                          e as
                            | "LIAM Shared Service (One Identity)"
                            | "Other"
                            | "No local IAM Tool"
                            | undefined
                        )
                      }
                      label={""}
                      name={`localIAMTool`}
                      options={[
                        "LIAM Shared Service (One Identity)",
                        "Other",
                        "No local IAM Tool",
                      ].map((type) => ({
                        value: type,
                        label: type,
                      }))}
                    />
                  </Box>
                  {currentLocalTool === "Other" && (
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        gap: 1,
                        alignItems: "center",
                      }}
                    >
                      <Typography>Name of (other) tool</Typography>
                      <InputFormField label={""} name={`otherLocalIAMTool`} />
                    </Box>
                  )}

                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      gap: 1,
                      alignItems: "center",
                    }}
                  >
                    <Typography># Local IT Assets</Typography>
                    <InputFormField
                      label={""}
                      name={`kpiNumberOfLocalITAssets`}
                      isNumeric
                    />
                  </Box>

                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      gap: 1,
                      alignItems: "center",
                    }}
                  >
                    <Typography>
                      # Local IT Assets oboarded to IAM Tooling
                    </Typography>
                    <InputFormField
                      label={""}
                      name={`kpiLocalITAssetsOnboardedToIAMTooling`}
                      isNumeric
                    />
                  </Box>

                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      gap: 1,
                      alignItems: "center",
                    }}
                  >
                    <Typography>
                      % Authorization concepts for IT Assets
                    </Typography>
                    <InputFormField
                      label={""}
                      name={`kpiNumberOfAuthorizationConceptsForITAssets`}
                      isNumeric
                    />
                  </Box>
                </>
              )}
            </Box>
          </FormField>
        </FormFields>
      </FormRow>
    </div>
  );
};
